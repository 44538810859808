<template>
  <v-navigation-drawer app floating permanent stateless :width="250" class="app-navigation-menu">
    <!-- Navigation Header -->
    <div class="background vertical-nav-header d-flex items-center ps-6 pt-2 pb-2">
      <router-link to="/" class="d-flex align-center text-decoration-none">
        <v-img src="../../../public/white-logo.png" width="30px" alt="logo" contain eager class="app-logo mr-1"></v-img>
        <v-slide-x-transition>
          <h2 class="app-title">Onyshare</h2>
        </v-slide-x-transition>
      </router-link>
    </div>

    <!-- Navigation Items -->
    <v-list expand class="vertical-nav-menu-items mt-4">
      <!-- <nav-menu-item title="Dashboard" to="/dashboard" exact icon="mdi-home-outline"></nav-menu-item> -->
      <nav-menu-item title="Dashboard" to="/admin/dashboard" icon="mdi-view-dashboard"></nav-menu-item>
      <nav-menu-item title="Users" to="/admin/users" icon="mdi-account-group"
        v-if="permissionCheck('SYSTEM_USERS')"></nav-menu-item>
      <nav-menu-item title="Ranks" to="/admin/ranks" icon="mdi-account-edit"
        v-if="permissionCheck('SYSTEM_RANKS')"></nav-menu-item>
      <nav-menu-item title="Send Email" to="/admin/email" icon="mdi-email-fast-outline"
        v-if="permissionCheck('SYSTEM_EMAIL')"></nav-menu-item>
      <nav-menu-item title="Tickets" to="/admin/tickets" icon="mdi-ticket"
        v-if="permissionCheck('SYSTEM_TICKETS')"></nav-menu-item>

    </v-list>
  </v-navigation-drawer>
</template>

<script>
import NavMenuItem from "../dashboard/NavMenuItem.vue";
import NavMenuSectionTitle from "../dashboard/NavMenuSectionTitle.vue";

export default {
  name: "VerticalNavMenu",
  components: {
    NavMenuItem,
    NavMenuSectionTitle,
  },
  methods: {
    permissionCheck(name) {
      if (this.$store.state.user.userPermissions.includes(name) || this.$store.state.user.userPermissions.includes("SUPER_ADMIN")) {
        return true;
      }

      return false;
    },
    isMobile() {
      if (this.$vuetify.breakpoint.sm || this.$vuetify.breakpoint.md || this.$vuetify.breakpoint.lg || this.$vuetify.breakpoint.xl) {
        return false;
      }

      return true;
    },
    GetMobileSize() {
      if (this.isMobile()) {
        return "60";
      }
      return "250"
    },
  },
  computed: {
    IsLoading() {
      return this.loading;
    }
  },
  props: {
    loading: {
      type: Boolean,
      required: true,
    },
  }
};
</script>

<style scoped>
.app-title {
  font-size: 1.25rem;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.3px;
  color: white;
}

.background {
  background-color: #1976d2;
}</style>
