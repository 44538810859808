<template>
  <v-row>
    <v-col>
      <v-row v-if="loading" justify="center" class="mt-15">
        <v-progress-circular class="ma-5" :size="70" :width="7" color="primary" indeterminate></v-progress-circular>
      </v-row>
      <VerticalAdminMenu :loading="loading"></VerticalAdminMenu>

      <template>
        <v-card-title class="pa-0 ma-1 pb-0 mb-0" v-if="!loading">
          <div class="monthTitle">
            Users
          </div>
        </v-card-title>
      </template>

      <template v-if="!loading">
        <v-text-field v-model="search" append-icon="mdi-magnify" label="Search" single-line hide-details></v-text-field>
        <v-data-table :headers="headers" :items="users" :items-per-page="itemsPerPage" class="elevation-1"
          :search="search" :footer-props="{
            'items-per-page-options': [10, 20, 30, 40, 50]
          }">

          <template v-slot:item.size="{ item }">
            <span v-if="item.size > 0">
              {{ SizeToReadable(item.size) }}
            </span>
          </template>

          <template v-slot:item.history="{ item }">
            <span v-if="item.history.length > 0">
              {{ item.history.length }}
            </span>
          </template>

          <template v-slot:item.captures="{ item }">
            <span v-if="item.captures > 0">
              {{ item.captures }}
            </span>
          </template>

          <template v-slot:item.userLevel="{ item }">
            <span v-if="item.userLevel > 0">
              {{ GetRankLevelName(item.userLevel) }}
            </span>
          </template>

          <template v-slot:item.actions="{ item }">
            <v-icon small class="mr-2" @click="viewUser(item)">
              mdi-eye-outline
            </v-icon>
          </template>

          <template v-slot:item.emailValidated="{ item }">
            <v-icon small v-if="item.emailValidated">
              mdi-check
            </v-icon>
          </template>

          <template v-slot:item.banned="{ item }">
            <v-icon small v-if="item.banned">
              mdi-check
            </v-icon>
          </template>

          <template v-slot:item.premium="{ item }">
            <v-icon small v-if="item.premium">
              mdi-star
            </v-icon>
          </template>

          <template v-slot:item.createdAt="{ item }">
            <span>
              {{ getCreatedAtDate(item.createdAt) }}
            </span>
          </template>
        </v-data-table>
      </template>

      <!-- View User -->
      <v-dialog v-model="viewUserDialog" max-width="700" persistent>
        <template>
          <v-card>
            <v-toolbar color="primary" dark>Viewing {{ viewedUser.email }}</v-toolbar>
            <div class="pt-5 pb-5 mb-0 ma-2">
              <v-text-field readonly class="pa-0 ma-2" :value="viewedUser.email" label="Email"></v-text-field>
              <v-text-field readonly class="pa-0 ma-2" :value="getCreatedAtDate(viewedUser.createdAt)" label="Created"></v-text-field>
              <v-text-field readonly class="pa-0 ma-2" :value="getCreatedAtDate(viewedUser.updatedAt)" label="Last Seen"></v-text-field>
              <v-text-field class="pa-0 ma-2" readonly :value="GetRankLevelName(viewedUser.userLevel)"
                label="Rank"></v-text-field>

              <div class="mb-0 ma-2 d-flex">
                <v-text-field readonly class="pa-0 ma-2" :value="SizeToReadable(viewedUser.size)"
                  label="Capture Size"></v-text-field>
                <v-text-field readonly class="pa-0 ma-2" :value="viewedUser.captures"
                  label="Number of Captures"></v-text-field>
                <v-text-field readonly class="pa-0 ma-2" :value="GetHistoryLength(viewedUser.history).length"
                  label="History"></v-text-field>
              </div>

              <div class="mb-0 ma-2 d-flex">
                <v-text-field readonly class="pa-0 ma-2" :value="viewedUser.emailValidated"
                  label="Validated"></v-text-field>
                <v-text-field readonly class="pa-0 ma-2" :value="viewedUser.premium" label="Premium"></v-text-field>
                <v-text-field readonly class="pa-0 ma-2" :value="viewedUser.banned" label="Banned"></v-text-field>
                <v-text-field readonly class="pa-0 ma-2" :value="viewedUser.twofactor" label="Two Factor"></v-text-field>
              </div>

            </div>
            <v-card-actions class="justify-end">
              <v-btn color="primary" text @click="viewUserDialog = false">Cancel</v-btn>
              <v-btn color="green" text
                @click="(viewUserDialog = false), (editUserRank = true), (selectedRankSet = 'Default')"
                v-if="permissionCheck('SYSTEM_USERS_SETRANK')">Edit Rank</v-btn>
              <v-btn color="green" text
                @click="(viewUserDialog = false), (capturesDialog = true), RequestUserCaptures(viewedUser)"
                v-if="permissionCheck('SYSTEM_USERS_CAPTURES')">View Captures</v-btn>
              <v-btn color="red" text @click="(viewUserDialog = false), (userBanDialog = true)"
                v-if="permissionCheck('SYSTEM_USERS_BAN') && !viewedUser.banned">Ban</v-btn>
              <v-btn color="red" text @click="(viewUserDialog = false), (userBanDialog = true)"
                v-if="permissionCheck('SYSTEM_USERS_BAN') && viewedUser.banned">Unban</v-btn>
              <v-btn color="green" text @click="(viewUserDialog = false), (user2faDialog = true)"
                v-if="permissionCheck('SYSTEM_USERS_TWOFACTOR') && !viewedUser.twofactor">Enable 2fa</v-btn>
              <v-btn color="green" text @click="(viewUserDialog = false), (user2faDialog = true)"
                v-if="permissionCheck('SYSTEM_USERS_TWOFACTOR') && viewedUser.twofactor">Disable 2fa</v-btn>
            </v-card-actions>
          </v-card>
        </template>
      </v-dialog>

      <!-- Set Rank -->
      <v-dialog v-model="editUserRank" max-width="350" persistent>
        <template>
          <v-card>
            <v-toolbar color="primary" dark>Edit Rank</v-toolbar>
            <v-card-text>
              <div class="pt-5 pb-0 mb-0">
                <v-text-field outlined class="pa-0 ma-0" readonly :value="GetRankLevelName(viewedUser.userLevel)"
                  label="Current Rank Level"></v-text-field>
                <v-select label="Set New Rank Level" :items="GetRankNames()" outlined width="50px" class="pa-0 ma-0"
                  v-model="selectedRankSet" value="0"></v-select>
              </div>
            </v-card-text>
            <v-card-actions class="justify-end">
              <v-btn color="primary" text @click="(editUserRank = false), (viewUserDialog = true)">Back</v-btn>
              <v-btn color="green" text @click="(editUserRank = false), SetUserRank(viewedUser)"
                v-if="permissionCheck('SYSTEM_USERS_SETRANK')">Set Rank</v-btn>
            </v-card-actions>
          </v-card>
        </template>
      </v-dialog>

      <!-- Ban User -->
      <v-dialog v-model="userBanDialog" max-width="550" persistent>
        <template>
          <v-card>
            <v-toolbar color="primary" dark v-if="!viewedUser.banned">Issue ban?</v-toolbar>
            <v-toolbar color="primary" dark v-if="viewedUser.banned">Issue unban?</v-toolbar>
            <v-card-text>
              <div class="pt-6 pb-0 mb-0" v-if="!viewedUser.banned">
                Do you want to issue a ban to {{ viewedUser.email }}? This action will send an email to the user.
              </div>
              <div class="pt-6 pb-0 mb-0" v-if="viewedUser.banned">
                Do you want to issue a unban to {{ viewedUser.email }}? This action will send an email to the user.
              </div>
            </v-card-text>
            <v-card-actions class="justify-end">
              <v-btn color="primary" text @click="(userBanDialog = false), (viewUserDialog = true)">Back</v-btn>
              <v-btn color="red" text @click="(userBanDialog = false), SetBanUser(viewedUser)"
                v-if="permissionCheck('SYSTEM_USERS_SETRANK') && !viewedUser.banned">Yes, ban</v-btn>
              <v-btn color="green" text @click="(userBanDialog = false), SetBanUser(viewedUser)"
                v-if="permissionCheck('SYSTEM_USERS_SETRANK') && viewedUser.banned">Yes, unban</v-btn>
            </v-card-actions>
          </v-card>
        </template>
      </v-dialog>

      <!-- 2fa User -->
      <v-dialog v-model="user2faDialog" max-width="550" persistent>
        <template>
          <v-card>
            <v-toolbar color="primary" dark v-if="!viewedUser.twofactor">Turn on Two Factor?</v-toolbar>
            <v-toolbar color="primary" dark v-if="viewedUser.twofactor">Turn off Two Factor?</v-toolbar>
            <v-card-text>
              <div class="pt-6 pb-0 mb-0" v-if="!viewedUser.twofactor">
                Do you want to turn on Two Factor Authentication for {{ viewedUser.email }}? This action will send an
                email to the user.
              </div>
              <div class="pt-6 pb-0 mb-0" v-if="viewedUser.twofactor">
                Do you want to turn off Two Factor Authentication for {{ viewedUser.email }}? This action will send an
                email to the user.
              </div>
            </v-card-text>
            <v-card-actions class="justify-end">
              <v-btn color="primary" text @click="(user2faDialog = false), (viewUserDialog = true)">Back</v-btn>
              <v-btn color="green" text @click="(user2faDialog = false), Set2faUser(viewedUser)"
                v-if="permissionCheck('SYSTEM_USERS_TWOFACTOR') && !viewedUser.twofactor">Yes, turn on</v-btn>
              <v-btn color="green" text @click="(user2faDialog = false), Set2faUser(viewedUser)"
                v-if="permissionCheck('SYSTEM_USERS_TWOFACTOR') && viewedUser.twofactor">Yes, turn off</v-btn>
            </v-card-actions>
          </v-card>
        </template>
      </v-dialog>

      <!-- View Captures -->
      <v-dialog v-model="capturesDialog" max-width="1600" persistent scrollable>
        <template>
          <v-card>
            <v-toolbar color="primary" dark>Viewing {{ viewedUser.email }} captures</v-toolbar>
            <v-card-text>
              <div class="pt-6 pb-0 mb-0 text-center" v-if="capturesLoading">
                <v-row justify="center" class="mb-4">
                  <v-progress-circular class="ma-5" :size="70" :width="7" color="primary"
                    indeterminate></v-progress-circular>
                </v-row>
                Requesting {{ viewedUser.email }} captures.<br />This may take a while, please wait.
              </div>
              <div class="pt-6 pb-0 mb-0" v-if="!capturesLoading">

                <v-row class="">
                  <v-col :class="{
                    cols1: $vuetify.breakpoint.xs,
                    cols2: $vuetify.breakpoint.sm,
                    cols4: $vuetify.breakpoint.md,
                    cols7: $vuetify.breakpoint.lg,
                    cols10: $vuetify.breakpoint.xl,
                  }" class="ma-0 pa-2" v-for="capture in this.viewedUser.capturesList" :key="capture.file">
                    <v-hover v-slot="{ hover }">
                      <v-card :href="getCaptureURL(capture.file)" target="_blank" class="pr-1 pl-1 pt-1"
                        :class="{ 'on-hover': hover }" :elevation="hover ? 10 : 2" :ripple="false" max-width="140"
                        max-height="140" height="140" width="140" :style="capture.selected
                            ? 'border: 1px solid #1976D2;'
                            : 'border: 1px solid transparent;'
                          ">
                        <div>
                          <v-img class="pa-0" height="100" contain :src="getCaptureSrc(capture)">
                          </v-img>
                        </div>
                        <v-card-text class="captureTitle pt-0 pb-5 pl-1 pr-1 text-center">
                          {{ capture.name }}
                        </v-card-text>
                      </v-card>
                    </v-hover>
                  </v-col>
                </v-row>

              </div>
            </v-card-text>
            <v-card-actions class="justify-end">
              <v-btn color="primary" text @click="(capturesDialog = false), (viewUserDialog = true)">Back</v-btn>
            </v-card-actions>
          </v-card>
        </template>
      </v-dialog>

    </v-col>
  </v-row>
</template>

<script>
import VerticalAdminMenu from "./VerticalAdminMenu.vue";
import config from "../../config";
export default {
  name: "Users",
  components: {
    VerticalAdminMenu,
  },
  data: function () {
    return {
      loading: true,
      selectedItem: false,
      users: [],
      ranks: [],
      viewUserDialog: false,
      itemsPerPage: 50,
      page: 1,
      search: '',
      editUserRank: false,
      userBanDialog: false,
      user2faDialog: false,
      selectedRankSet: 'Default',
      capturesDialog: false,
      capturesLoading: true,
      viewedUser: {},
      hover: false,
      headers: [
        {
          text: 'Email',
          align: 'start',
          sortable: true,
          value: 'email',
          filterable: true,
          width: 400
        },
        { text: 'Validated', value: 'emailValidated', sortable: true, filterable: false, width: 100 },
        { text: 'Premium', value: 'premium', sortable: true, filterable: false, width: 100 },
        { text: 'Banned', value: 'banned', sortable: true, filterable: false, width: 100 },
        { text: 'Data Size', value: "size", filterable: true, width: 100 },
        { text: 'Captures', value: 'captures', filterable: false, width: 100 },
        { text: 'History', value: 'history', filterable: false, width: 100 },
        { text: 'Rank Level', value: 'userLevel', filterable: true, width: 125 },
        { text: 'Created', value: 'createdAt', filterable: true, width: 125 },
        { text: 'Actions', value: 'actions', sortable: false, width: 100 },
      ],
    };
  },
  methods: {
    getCreatedAtDate(createdAt) {
      var date = new Date(createdAt)
      return (date.toLocaleString('en-GB', {day:'numeric', month: 'numeric', year:'numeric'}));
    },
    getSmallerText(text) {
      if (text.length > 17) {
        return text.substring(0, 17) + "...";
      }
      return text;
    },
    getCaptureURL(captureID) {
      //window.open(config.URL + "/c/" + captureID, '_blank');
      return config.URL + "/c/" + captureID;
      //return "http://localhost:8080" + "/c/" + captureID;
    },
    getCaptureSrc(capture) {
      if (capture.type == "mp4") {
        capture.url = capture.url.replace(".mp4", ".gif");
      }

      if (capture.type == "txt") {
        return "https://c.onyshare.com/file/onyshare-eu/default-code.png"
      }

      return capture.url;
    },
    GetHistoryLength(history) {
      if (history == null) {
        history = [];
      }
      return history;
    },
    SizeToReadable(size) {
      if (size / 1024 / 1024 >= 1000) {
        return `${(size / 1024 / 1024 / 1024).toFixed(2)} GB`;
      } else if (size / 1024 / 1024 >= 1) {
        return `${(size / 1024 / 1024).toFixed(2)} MB`;
      } else if (size / 1024 / 1024 < 1) {
        return `${(size / 1024).toFixed(2)} KB`;
      }
    },
    permissionCheck(name) {
      if (this.$store.state.user.userPermissions.includes(name) || this.$store.state.user.userPermissions.includes("SUPER_ADMIN")) {
        return true;
      }

      return false;
    },
    GetRankLevelByRankName(name) {
      for (let i = 0; i < this.ranks.length; i++) {
        if (name == this.ranks[i].name) {
          return this.ranks[i].userLevel;
        }
      }
    },
    GetRankNames() {
      var rankNames = [];

      for (let i = 0; i < this.ranks.length; i++) {
        rankNames.push(this.ranks[i].name);
      }

      return rankNames;
    },
    GetRankLevelName(userLevel) {
      for (let i = 0; i < this.ranks.length; i++) {
        if (this.ranks[i].userLevel == userLevel) {
          return this.ranks[i].name;
        }
      }

      return '';
    },
    viewUser(item) {
      this.viewedUser = item;
      this.viewUserDialog = true;
    },
    SetUserRank(viewedUser) {
      var data = [];
      data.push(viewedUser.email);
      data.push(viewedUser.userLevel);
      data.push(this.GetRankLevelByRankName(this.selectedRankSet));

      this.$fetch(`${config.URL}/admin/users/setrank`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(data),
      })
        .then((res) => {
          if (res.status != 200) {
            this.loading = false;
            this.$toasted.show("Failed to set user rank", {
              theme: "toasted-primary",
              type: "error",
              position: "top-center",
              duration: 2000,
              iconPack: "mdi",
              icon: "file-alert-outline",
            });
          } else {
            res.json().then((data) => {
              this.$toasted.show("Successfully set user rank", {
                theme: "toasted-primary",
                type: "info",
                position: "top-center",
                duration: 2000,
                iconPack: "mdi",
                icon: "file-account-outline",
              });
              this.users = data.users;
              this.ranks = data.ranks;
            });
          }
        })
        .catch((err) => {
          this.$toasted.show("Failed to set user rank", {
            theme: "toasted-primary",
            type: "error",
            position: "top-center",
            duration: 2000,
            iconPack: "mdi",
            icon: "file-alert-outline",
          });
        });
    },
    SetBanUser(viewedUser) {
      var data = [];
      data.push(viewedUser.email);
      data.push(!viewedUser.banned);

      this.$fetch(`${config.URL}/admin/users/ban`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(data),
      })
        .then((res) => {
          if (res.status != 200) {
            this.loading = false;
            this.$toasted.show("Failed to ban or unban user", {
              theme: "toasted-primary",
              type: "error",
              position: "top-center",
              duration: 2000,
              iconPack: "mdi",
              icon: "file-alert-outline",
            });
          } else {
            res.json().then((data) => {
              var str = "";
              if (viewedUser.banned == false) {
                str = "banned";
              } else {
                str = "unbanned";
              }
              this.$toasted.show(`Successfully ${str} user!`, {
                theme: "toasted-primary",
                type: "info",
                position: "top-center",
                duration: 2000,
                iconPack: "mdi",
                icon: "file-account-outline",
              });
              this.users = data.users;
              this.ranks = data.ranks;
            });
          }
        })
        .catch((err) => {
          this.$toasted.show("Failed to ban or unban user", {
            theme: "toasted-primary",
            type: "error",
            position: "top-center",
            duration: 2000,
            iconPack: "mdi",
            icon: "file-alert-outline",
          });
        });
    },
    Set2faUser(viewedUser) {
      var data = [];
      data.push(viewedUser.email);
      data.push(!viewedUser.twofactor);

      this.$fetch(`${config.URL}/admin/users/2fa`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(data),
      })
        .then((res) => {
          if (res.status != 200) {
            this.loading = false;
            this.$toasted.show("Failed to set user 2fa", {
              theme: "toasted-primary",
              type: "error",
              position: "top-center",
              duration: 2000,
              iconPack: "mdi",
              icon: "file-alert-outline",
            });
          } else {
            res.json().then((data) => {
              var str = "";
              if (viewedUser.twofactor == false) {
                str = "enabled";
              } else {
                str = "disabled";
              }
              this.$toasted.show(`Successfully ${str} user 2fa`, {
                theme: "toasted-primary",
                type: "info",
                position: "top-center",
                duration: 2000,
                iconPack: "mdi",
                icon: "file-account-outline",
              });
              this.users = data.users;
              this.ranks = data.ranks;
            });
          }
        })
        .catch((err) => {
          this.$toasted.show("Failed to set user 2fa", {
            theme: "toasted-primary",
            type: "error",
            position: "top-center",
            duration: 2000,
            iconPack: "mdi",
            icon: "file-alert-outline",
          });
        });
    },
    RequestUserCaptures(viewedUser) {
      viewedUser.capturesList = [];
      this.capturesLoading = true;
      var data = [];
      data.push(viewedUser.email);

      this.$fetch(`${config.URL}/admin/users/captures`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(data),
      })
        .then((res) => {
          if (res.status != 200) {
            this.capturesLoading = true;
          } else {
            res.json().then((data) => {
              this.viewedUser.capturesList = data;
              this.capturesLoading = false;
            });
          }
        })
        .catch((err) => {
          this.capturesLoading = true;
        });
    },
  },
  created: function () {
    this.$fetch(`${config.URL}/admin/userslist`)
      .then((res) => {
        if (res.status === 200) {
          res.json().then((data) => {
            this.users = data.users;
            this.ranks = data.ranks;
            this.loading = false;
          });
        } else {
          this.loading = true;
          this.$router.push("/");
        }
      })
      .catch((err) => {
        this.loading = true;
        this.$router.push("/");
      });
  },
};
</script>

<style>
.cols10 {
  width: 10%;
  max-width: 10%;
  flex-basis: 10%;
}

.cols7 {
  width: 14.285%;
  max-width: 14.285%;
  flex-basis: 14.285%;
}

.cols4 {
  width: 25%;
  max-width: 25%;
  flex-basis: 25%;
}

.cols3 {
  width: 33%;
  max-width: 33%;
  flex-basis: 33%;
}

.cols2 {
  width: 50%;
  max-width: 50%;
  flex-basis: 50%;
}

.cols1 {
  width: 100%;
  max-width: 100%;
  flex-basis: 100%;
}

.v-checkbox {
  color: white;
}

.captureTitle {
  font-size: 0.74em;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.listText {
  font-size: 0.8rem;
  font-weight: 400;
  margin: 0%;
  padding: 0%;
  vertical-align: middle;
  justify-content: left;
  align-content: baseline;
  align-self: baseline;
  align-items: baseline;
}

.monthTitle {
  font-size: 1.25rem;
  font-weight: 700;
  color: white;
  background-color: #1976d2;
  width: 100%;
  height: 50px;
  text-align: center;
  display: table-cell;
  vertical-align: middle;
  line-height: 50px;
}

.select {
  width: 60px;
}

.v-select__selections input {
  display: none
}
</style>
