<template>
  <v-row>
    <v-col>
      <v-row v-if="loading" justify="center" class="mt-15">
        <v-progress-circular class="ma-5" :size="70" :width="7" color="primary" indeterminate></v-progress-circular>
      </v-row>
      <VerticalAdminMenu :loading="loading"></VerticalAdminMenu>

      <template>
        <v-card-title class="pa-0 ma-1 pb-0 mb-0" v-if="!loading">
          <div class="monthTitle">
            Ranks
            <v-icon dark size="24px" class="mb-1" @click="OpenCreateRank()">mdi-plus</v-icon>
          </div>
        </v-card-title>
      </template>

      <template v-if="!loading">
        <v-card tile class="pa-0 ma-1 pb-0 mb-0">

          <v-data-table :headers="headers" :items="ranks" :items-per-page="5" class="elevation-1">
            <template v-slot:item.actions="{ item }">
              <v-icon small class="mr-2" @click="OpenEditRank(item.name, item.userLevel, item.permissions)">
                mdi-pencil
              </v-icon>
              <v-icon small @click="DeleteRank(item.name, item.userLevel, item.permissions)">
                mdi-delete
              </v-icon>
            </template>
          </v-data-table>
        </v-card>
      </template>

      <v-dialog v-model="editRank" max-width="500" persistent>
        <template>
          <v-card>
            <v-toolbar color="primary" dark>Edit Rank</v-toolbar>
            <v-card-text>
              <div class="pt-5 pb-0 mb-0">
                You cannot edit the rank level after it has been created.
                <v-text-field label="Name" outlined hide-details single-line class="pa-2"
                  v-model="rankName"></v-text-field>
                <v-text-field label="Level" outlined hide-details single-line disabled type="number" class="pa-2"
                  v-model="rankLevel"></v-text-field>
                <v-select label="Select Permissions" :items="GetPermissions" multiple outlined chips clearable
                  width="50px" hide-details class="pa-2" v-model="rankPermissions"></v-select>
              </div>
            </v-card-text>
            <v-card-actions class="justify-end">
              <v-btn color="primary" text @click="editRank = false">Cancel</v-btn>
              <v-btn color="green" text @click="(editRank = false), EditRank()">Edit</v-btn>
            </v-card-actions>
          </v-card>
        </template>
      </v-dialog>

      <v-dialog v-model="createRank" max-width="500" persistent>
        <template>
          <v-card>
            <v-toolbar color="primary" dark>Create Rank</v-toolbar>
            <v-card-text>
              <div class="pt-5 pb-0 mb-0">
                <v-text-field label="Name" outlined hide-details single-line class="pa-2"
                  v-model="rankName"></v-text-field>
                <v-text-field label="Level" outlined hide-details single-line type="number" class="pa-2"
                  v-model="rankLevel"></v-text-field>
                <v-select label="Select Permissions" :items="GetPermissions" multiple outlined chips clearable
                  width="50px" hide-details class="pa-2" v-model="rankPermissions"></v-select>
              </div>
            </v-card-text>
            <v-card-actions class="justify-end">
              <v-btn color="primary" text @click="createRank = false">Cancel</v-btn>
              <v-btn color="green" text @click="(createRank = false), SendRank()">Create</v-btn>
            </v-card-actions>
          </v-card>
        </template>
      </v-dialog>

    </v-col>
  </v-row>
</template>

<script>
import VerticalAdminMenu from "./VerticalAdminMenu.vue";
import config from "../../config";
export default {
  name: "Ranks",
  components: {
    VerticalAdminMenu,
  },
  data: function () {
    return {
      loading: true,
      selectedItem: false,
      ranks: [],
      permissions: [],
      createRank: false,
      editRank: false,
      rankName: "",
      rankLevel: 1,
      rankPermissions: [],
      itemsPerPage: 5,
      page: 1,
      headers: [
        {
          text: 'Rank Name',
          align: 'start',
          sortable: false,
          value: 'name',
        },
        { text: 'Permissions', value: 'permissions',sortable: false,},
        { text: 'Rank Level', value: 'userLevel',sortable: false, },
        { text: 'Actions', value: 'actions', sortable: false, },
      ],
    };
  },
  methods: {
    DeleteRank(name, level, permissions) {
      var data = [];
      data.push(name);
      data.push(level);
      data.push(permissions);

      this.$fetch(`${config.URL}/admin/userranks/delete`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(data),
      })
        .then((res) => {
          if (res.status != 200) {
            this.loading = false;
            this.$toasted.show("Failed to delete rank", {
              theme: "toasted-primary",
              type: "error",
              position: "top-center",
              duration: 2000,
              iconPack: "mdi",
              icon: "file-alert-outline",
            });
          } else {
            res.json().then((data) => {
              this.$toasted.show("Successfully deleted rank", {
                theme: "toasted-primary",
                type: "info",
                position: "top-center",
                duration: 2000,
                iconPack: "mdi",
                icon: "file-account-outline",
              });
              this.ranks = data.ranks;
            });
          }
        })
        .catch((err) => {
          this.$toasted.show("Failed to delete rank", {
            theme: "toasted-primary",
            type: "error",
            position: "top-center",
            duration: 2000,
            iconPack: "mdi",
            icon: "file-alert-outline",
          });
        });
    },
    OpenEditRank(name, userLevel, permissions) {
      this.rankName = name;
      this.rankLevel = userLevel;
      this.rankPermissions = permissions;

      this.editRank = true;
    },
    OpenCreateRank() {
      this.rankName = "";
      this.rankLevel = 1;
      this.rankPermissions = [];

      this.createRank = true;
    },
    EditRank() {
      var data = [];
      data.push(this.rankName);
      data.push(this.rankLevel);
      data.push(this.rankPermissions);

      this.$fetch(`${config.URL}/admin/userranks/edit`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(data),
      })
        .then((res) => {
          if (res.status != 200) {
            this.loading = false;
            this.$toasted.show("Failed to edit user rank", {
              theme: "toasted-primary",
              type: "error",
              position: "top-center",
              duration: 2000,
              iconPack: "mdi",
              icon: "file-alert-outline",
            });
          } else {
            res.json().then((data) => {
              this.$toasted.show("Successfully edited user rank", {
                theme: "toasted-primary",
                type: "info",
                position: "top-center",
                duration: 2000,
                iconPack: "mdi",
                icon: "file-account-outline",
              });
              this.ranks = data.ranks;
              this.rankName = "";
              this.rankLevel = 1;
              this.rankPermissions = [];
            });
          }
        })
        .catch((err) => {
          this.$toasted.show("Failed to edit user rank", {
            theme: "toasted-primary",
            type: "error",
            position: "top-center",
            duration: 2000,
            iconPack: "mdi",
            icon: "file-alert-outline",
          });
        });
    },
    SendRank() {
      var data = [];
      data.push(this.rankName);
      data.push(this.rankLevel);
      data.push(this.rankPermissions);

      this.$fetch(`${config.URL}/admin/userranks/create`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(data),
      })
        .then((res) => {
          if (res.status != 200) {
            this.loading = false;
            this.$toasted.show("Failed to create user rank", {
              theme: "toasted-primary",
              type: "error",
              position: "top-center",
              duration: 2000,
              iconPack: "mdi",
              icon: "file-alert-outline",
            });
          } else {
            res.json().then((data) => {
              this.$toasted.show("Successfully saved user rank", {
                theme: "toasted-primary",
                type: "info",
                position: "top-center",
                duration: 2000,
                iconPack: "mdi",
                icon: "file-account-outline",
              });
              this.ranks = data.ranks;
              this.rankName = "";
              this.rankLevel = 1;
              this.rankPermissions = [];
            });
          }
        })
        .catch((err) => {
          this.$toasted.show("Failed to create user rank", {
            theme: "toasted-primary",
            type: "error",
            position: "top-center",
            duration: 2000,
            iconPack: "mdi",
            icon: "file-alert-outline",
          });
        });
    },
  },
  computed: {
    GetRanks() {
      return this.ranks;
    },
    GetPermissions() {
      return this.permissions;
    }
  },
  created: function () {
    this.$fetch(`${config.URL}/admin/userranks`)
      .then((res) => {
        if (res.status === 200) {
          res.json().then((data) => {
            this.permissions = data.perms.permissions;
            this.ranks = data.ranks;
            this.loading = false;
          });
        } else {
          this.loading = true;
          this.$router.push("/");
        }
      })
      .catch((err) => {
        this.loading = true;
        this.$router.push("/");
      });
  },
};
</script>

<style>
.layout {
  display: inline-block;
  width: 100%;
}
.monthTitle {
  font-size: 1.25rem;
  font-weight: 700;
  color: white;
  background-color: #1976d2;
  width: 100%;
  height: 50px;
  text-align: center;
  display: table-cell;
  vertical-align: middle;
  line-height: 50px;
}

.select {
  width: 60px;
}

.v-select__selections input {
  display: none
}
</style>
