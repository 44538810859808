<template>
  <v-row>
    <v-col>
      <v-row v-if="loading" justify="center" class="mt-15">
        <v-progress-circular class="ma-5" :size="70" :width="7" color="primary" indeterminate></v-progress-circular>
      </v-row>
      <VerticalAdminMenu :loading="loading"></VerticalAdminMenu>
      <!-- users row -->
      <v-row class="pl-1" justify="left" v-if="!loading && data.users">
        <template>
          <!-- total users -->
          <v-card class="ma-1" width="220" height="100" v-if="data.users.totalUsers != null">
            <v-progress-linear color="primary" height="10" value="100"></v-progress-linear>
            <span class="cardUsersTitle">Total Users</span>
            <div class="cardUsersValue">
              <v-icon color="primary">mdi-account</v-icon>
              {{ data.users.totalUsers.toLocaleString() }}<br />
            </div>
          </v-card>
          <!-- validated users -->
          <v-card class="ma-1" width="220" height="100" v-if="data.users.validatedUsers != null">
            <v-progress-linear color="light-blue" height="10" value="100"></v-progress-linear>
            <span class="cardUsersTitle">Validated Users</span>
            <div class="cardUsersValue">
              <v-icon color="primary">mdi-account-check</v-icon>
              {{ data.users.validatedUsers.toLocaleString() }}<br />
            </div>
          </v-card>
          <!-- none validated users -->
          <v-card class="ma-1" width="220" height="100" v-if="data.users.nonValidatedUsers != null">
            <v-progress-linear color="#81D4FA" height="10" value="100"></v-progress-linear>
            <span class="cardUsersTitle">Non Validated Users</span>
            <div class="cardUsersValue">
              <v-icon color="primary">mdi-account-remove</v-icon>
              {{ data.users.nonValidatedUsers.toLocaleString() }}<br />
            </div>
          </v-card>
          <!-- banned users -->
          <v-card class="ma-1" width="220" height="100" v-if="data.users.bannedUsers != null">
            <v-progress-linear color="#B3E5FC" height="10" value="100"></v-progress-linear>
            <span class="cardUsersTitle">Banned Users</span>
            <div class="cardUsersValue">
              <v-icon color="primary">mdi-account-cancel</v-icon>
              {{ data.users.bannedUsers.toLocaleString() }}<br />
            </div>
          </v-card>
          <!-- banned users -->
          <v-card class="ma-1" width="220" height="100" v-if="data.users.twofactorUsers != null">
            <v-progress-linear color="#E1F5FE" height="10" value="100"></v-progress-linear>
            <span class="cardUsersTitle">Two-factor Enabled Users</span>
            <div class="cardUsersValue">
              <v-icon color="primary">mdi-account-key</v-icon>
              {{ data.users.twofactorUsers.toLocaleString() }}<br />
            </div>
          </v-card>

        </template>
      </v-row>
      <!-- captures row -->
      <v-row class="pl-1" justify="left" v-if="!loading && data.captures">
        <template>
          <!-- total captures -->
          <v-card class="ma-1" width="220" height="100" v-if="data.captures.totalCaptures != null">
            <v-progress-linear color="#FF9800" height="10" value="100"></v-progress-linear>
            <span class="cardUsersTitle">Total Captures</span>
            <div class="cardUsersValue">
              <v-icon color="primary">mdi-file-multiple</v-icon>
              {{ data.captures.totalCaptures.toLocaleString() }}<br />
            </div>
          </v-card>
          <!-- image captures -->
          <v-card class="ma-1" width="220" height="100" v-if="data.captures.imageCaptures != null">
            <v-progress-linear color="#FFA726" height="10" value="100"></v-progress-linear>
            <span class="cardUsersTitle">Image Captures</span>
            <div class="cardUsersValue">
              <v-icon color="primary">mdi-file</v-icon>
              {{ data.captures.imageCaptures.toLocaleString() }}<br />
            </div>
          </v-card>
          <!-- video captures -->
          <v-card class="ma-1" width="220" height="100" v-if="data.captures.videoCaptures != null">
            <v-progress-linear color="#FFB74D" height="10" value="100"></v-progress-linear>
            <span class="cardUsersTitle">Video Captures</span>
            <div class="cardUsersValue">
              <v-icon color="primary">mdi-file-video</v-icon>
              {{ data.captures.videoCaptures.toLocaleString() }}<br />
            </div>
          </v-card>
          <!-- codebin captures -->
          <v-card class="ma-1" width="220" height="100" v-if="data.captures.codebinCaptures != null">
            <v-progress-linear color="#FFCC80" height="10" value="100"></v-progress-linear>
            <span class="cardUsersTitle">Codebin Captures</span>
            <div class="cardUsersValue">
              <v-icon color="primary">mdi-file-code</v-icon>
              {{ data.captures.codebinCaptures.toLocaleString() }}<br />
            </div>
          </v-card>

        </template>
      </v-row>
      <!-- storage row -->
      <v-row class="pl-1" justify="left" v-if="!loading && data.storage">
        <template>
          <!-- total storage size -->
          <v-card class="ma-1" width="220" height="100" v-if="data.storage.totalSize != null">
            <v-progress-linear color="#9C27B0" height="10" value="100"></v-progress-linear>
            <span class="cardUsersTitle">Total Size Of Captures</span>
            <div class="cardUsersValue">
              <v-icon color="primary">mdi-file-multiple</v-icon>
              {{ SizeToReadable(data.storage.totalSize) }}<br />
            </div>
          </v-card>
          <!-- image storage size -->
          <v-card class="ma-1" width="220" height="100" v-if="data.storage.imageSize != null">
            <v-progress-linear color="#AB47BC" height="10" value="100"></v-progress-linear>
            <span class="cardUsersTitle">Image Captures Size</span>
            <div class="cardUsersValue">
              <v-icon color="primary">mdi-file</v-icon>
              {{ SizeToReadable(data.storage.imageSize) }}<br />
            </div>
          </v-card>
          <!-- video storage size -->
          <v-card class="ma-1" width="220" height="100" v-if="data.storage.videoSize != null">
            <v-progress-linear color="#BA68C8" height="10" value="100"></v-progress-linear>
            <span class="cardUsersTitle">Video Captures Size</span>
            <div class="cardUsersValue">
              <v-icon color="primary">mdi-file-video</v-icon>
              {{ SizeToReadable(data.storage.videoSize) }}<br />
            </div>
          </v-card>
          <!-- codebin storage size -->
          <v-card class="ma-1" width="220" height="100" v-if="data.storage.codebinSize != null">
            <v-progress-linear color="#CE93D8" height="10" value="100"></v-progress-linear>
            <span class="cardUsersTitle">Codebin Captures Size</span>
            <div class="cardUsersValue">
              <v-icon color="primary">mdi-file-code</v-icon>
              {{ SizeToReadable(data.storage.codebinSize) }}<br />
            </div>
          </v-card>
        </template>
      </v-row>

    </v-col>
  </v-row>
</template>

<script>
import VerticalAdminMenu from "./VerticalAdminMenu.vue";
import config from "../../config";
export default {
  name: "AdminDashboard",
  components: {
    VerticalAdminMenu,
  },
  data: function () {
    return {
      loading: true,
      data: {}
    };
  },
  methods: {
    SizeToReadable(size) {
      if (size / 1024 / 1024 >= 1000) {
        return `${(size / 1024 / 1024 / 1024).toFixed(2)} GB`;
      } else if (size / 1024 / 1024 >= 1) {
        return `${(size / 1024 / 1024).toFixed(2)} MB`;
      } else if (size / 1024 / 1024 < 1) {
        return `${(size / 1024).toFixed(2)} KB`;
      }
    }
  },
  created: function () {
    this.$fetch(`${config.URL}/admin/getdashboard`)
      .then((res) => {
        if (res.status === 200) {
          res.json().then((data) => {
            this.data = data;
          });
          this.loading = false;
        } else {
          this.loading = true;
          this.$router.push("/");
        }
      })
      .catch((err) => {
        this.loading = true;
        this.$router.push("/");
      });
  },
};
</script>

<style>
.cardUsersTitle {
  font-size: 12px;
  font-weight: 400;
  text-transform: uppercase;
  color: grey;
  margin-left: 10px;
}

.cardUsersValue {
  font-size: 30px;
  font-weight: 500;
  margin-left: 10px;
  color: rgb(55, 55, 55);
}
</style>
